import { Option } from "@swan-io/boxed";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { FileInput } from "@swan-io/shared-business/src/components/FileInput";
import { useState } from "react";
import { t } from "../utils/i18n";

type Props = {
  onPressPrevious: () => void;
  onSave: (file?: File) => void;
  loading: boolean;
};

export const TransferRegularWizardFile = ({ onPressPrevious, onSave, loading }: Props) => {
  const onPressSubmit = () => {
    if (file.isNone()) {
      onSave();
      return;
    }
    onSave(file.get());
  };

  const [file, setFile] = useState<Option<File>>(Option.None());

  return (
    <>
      <Tile>
        <LakeLabel
          label={t("transfer.bulk.file")}
          render={() => (
            <FileInput
              icon="document-regular"
              accept={["application/pdf"]}
              value={file.toUndefined()}
              onFiles={files => {
                const file = files[0];
                setFile(Option.fromNullable(file));
              }}
              disabled={loading}
            />
          )}
        />
      </Tile>

      <Space height={32} />

      <ResponsiveContainer breakpoint={800}>
        {({ small }) => (
          <LakeButtonGroup>
            <LakeButton
              color="gray"
              mode="secondary"
              onPress={onPressPrevious}
              grow={small}
              disabled={loading}
            >
              {t("common.previous")}
            </LakeButton>

            <LakeButton color="current" onPress={onPressSubmit} grow={small} loading={loading}>
              {t("common.continue")}
            </LakeButton>
          </LakeButtonGroup>
        )}
      </ResponsiveContainer>
    </>
  );
};
